<template>
    <div class="w-full max-w-[500px] mx-auto">
        <div class="text-center px-4">
            <img class="h-[100px] mx-auto" src="https://img.freepik.com/vecteurs-premium/modele-logo-boulangerie_441059-120.jpg" alt="">
            <h1 class="text-3xl font-bold">Recevez votre surprise</h1>
            <h3 class="text-xl font-light mt-1">Retournez en magasin pour recevoir votre surprise</h3>
        </div>
        <div class="px-4">
            <div class="bg-slate-200 rounded-xl p-6 mt-10 text-center font-bold text-2xl">
                1 viennoiserie offerte
            </div>
            <div class="font-light mt-6 text-center">Code valable pendant {{ timer | secondToTime }}</div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    data() {
        return {
            timer: 480,
        }
    },
    filters: {
        secondToTime: function(timer) {
            return moment.utc(timer * 1000).format('mm:ss');
        }
    },
    mounted () {
        setInterval(() => {
            this.timer--
        }, 1000)
    },
}
</script>