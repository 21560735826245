import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Prize from '../views/Prize.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'home', component: Home},
  {path: '/prize', name: 'prize', component: Prize},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
