<template>
    <div class="w-full max-w-[500px] mx-auto">
        <div class="text-center">
            <img class="h-[100px] mx-auto" src="https://img.freepik.com/vecteurs-premium/modele-logo-boulangerie_441059-120.jpg" alt="">
            <h1 class="text-3xl font-bold">Laissez un avis Google</h1>
            <h3 class="text-xl font-light mt-1">Et gagnez une suprise</h3>
        </div>
        <div class="px-4 mt-10 pb-20">
            <div v-if="!sendedMail">
                <div>
                    <div class="text-xl">1. Ajouter un avis Google</div>
                    <p class="font-light mt-1">Commencez par ajouter un avis sur la page Google. Une fois l'avis ajouté, revenez sur cette page</p>
                    <div class="flex">
                        <a target="_blank" @click="onOpenGoogle" href="https://www.google.com/search?sca_esv=139173b825692227&sca_upv=1&tbm=lcl&sxsrf=ACQVn0_im3zmzrIlcV5wk4szS2cWjGmuGQ:1713813076459&q=Boulangerie+patisserie+maison+Bonaccio+Avis&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxIxNLAwNbU0MrUwNzC1NDaxNDA0N9rAyPiKUdspvzQnMS89tSgzVaEgsSSzuBjMzE3MLM7PU3DKz0tMTs7MV3AsyyxexEqKagBPMTcydwAAAA&rldimm=10855925870593490172&hl=fr-FR&sa=X&ved=2ahUKEwi3iYidw9aFAxXAVqQEHf_TAWMQ9fQKegQINRAH&biw=1920&bih=968&dpr=1#lkt=LocalPoiReviews" class="px-4 py-2 text-slate-700 bg-white border shadow rounded mt-4 flex items-center gap-2 font-bold text-lg">
                            <img class="h-[20px]" src="https://cdn.iconscout.com/icon/free/png-256/free-google-1772223-1507807.png" alt="">
                            Ajouter un avis
                        </a>
                    </div>
                </div>
                <div v-show="displayValidation" class="pt-10 mt-10 border-t">
                    <div class="text-xl">2. Validation</div>
                    <p class="font-light mt-1">Votre avis Google doit être posté moins de 10 minutes avant la vérification</p>
                    <div @click="checkAvisAdded" class="flex items-center gap-3 mt-4"">
                        <template v-if="loadingCheck">
                            <div class="loader"></div> Vérification en cours..
                        </template>
                        <div class="bg-slate-100 px-4 py-2 rounded text-lg font-bold border" v-else>
                            Vérifier
                        </div>
                    </div>
                </div>
                <div v-show="displayWheel" class="pt-10 mt-10 border-t">
                    <div class="text-xl">3. Faîtes tourner la roue</div>
                    <p class="font-light mt-1">C'est le moment de découvrir votre surprise !</p>
                    <div class="mt-4 mb-4">
                        <button v-if="!spinning" class="px-4 py-2 bg-slate-100 text-lg font-bold border rounded" @click="runGame">Lancer la roue</button>
                    </div>
                    <div v-show="!selectedPrize">
                        <FortuneWheel
                        class="wheel -ml-[220px] mt-10"
                        style="width: 400px"
                        :canvas="canvasOptions"
                        :prizes="prizes"
                        :disabled="disabled"
                        :verify="cavansVerify"
                        @rotateStart="onCanvasRotateStart"
                        @rotateEnd="onRotateEnd"
                        />
                    </div>
                    <div class="p-6 rounded text-center bg-slate-100" v-if="selectedPrize">
                        <p>Vous avez gagné:</p>
                        <h1 class="text-2xl font-bold">{{ selectedPrize }}</h1>
                    </div>
                </div>
                <div v-show="selectedPrize" class="pt-10 mt-10 border-t">
                    <div class="text-xl">4. Recevoir votre surprise</div>
                    <p class="font-light mt-1">Vous receverez un email contenant le justificatif de votre cadeau</p>
                    <input v-model="email" type="text" class="p-4 text-lg w-full border rounded-lg mt-4 focus:outline-none" placeholder="Votre adresse email">
                    <button @click="sendedMail = true" class="px-4 py-2 rounded bg-green-500 text-white mt-4 text-lg">Recevoir ma surprise <i class="fas fa-check ml-1"></i></button>
                </div>
            </div>
            <div v-if="sendedMail" class="p-4 rounded bg-green-500 text-white text-xl text-center">
                <div class="mb-2 text-2xl"><i class="fas fa-check-circle"></i></div>
                <div class="font-light">Un email contenant le justificatif de votre surprise a été envoyé à <b class="font-bold">{{ email }}</b></div>
            </div>
        </div>
    </div>
</template>

<script>
import FortuneWheel from 'vue-fortune-wheel'
import JSConfetti from 'js-confetti'
import 'vue-fortune-wheel/lib/vue-fortune-wheel.css'
// import moment from 'moment'

export default {
    components: {
        FortuneWheel
    },
    data() {
        return {
            timer: 480,
            displayValidation: false,
            loadingCheck: false,
            displayWheel: false,
            displayPrize: false,
            spinning: false,
            selectedPrize: null,
            email: null,
            sendedMail: false,

            cavansVerify: true,
            canvasOptions: {
                borderWidth: 6,
                borderColor: '#584b43',
                btnWidth: 100,
                btnText: '',
                prizeId: 1,
            },
            prizes: [
                {
                    id: 1, //* The unique id of each prize, an integer greater than 0
                    name: 'Viennoiserie', // Prize name, display value when type is canvas (this parameter is not needed when type is image)
                    value: 'Viennoiserie', //* Prize value, return value after spinning
                    bgColor: '#ffd700', // Background color (no need for this parameter when type is image)
                    color: '#444', // Font color (this parameter is not required when type is image)
                    probability: 100, //* Probability, up to 4 decimal places (the sum of the probabilities of all prizes
                    weight: 1 // Weight, if useWeight is true, the probability is calculated by weight (weight must be an integer), so probability is invalid
                },
                {
                    id: 2,
                    name: 'Patisserie',
                    value: 'Patisserie',
                    bgColor: '#fff',
                    color: '#444',
                    probability: 0,
                    weight: 1
                },
                {
                    id: 3,
                    name: 'Sandwich',
                    value: 'Sandwich',
                    bgColor: '#ffd700',
                    color: '#ffffff',
                    probability: 0,
                    weight: 1
                },
                {
                    id: 4,
                    name: 'Boisson',
                    value: 'Boisson',
                    bgColor: '#fff',
                    color: '#444',
                    probability: 0,
                    weight: 1
                }
            ],
            rotateFunc: null,
            disabled: false,
        }
    },
    // filters: {
    //     secondToTime: function(timer) {
    //         return moment.utc(timer * 1000).format('mm:ss');
    //     }
    // },
    methods: {
        onCanvasRotateStart(rotate) {
            this.rotateFunc = rotate
            // rotate()
        },
        onRotateEnd(prize) {
            let jsConfetti = new JSConfetti()
            jsConfetti.addConfetti({
                confettiRadius: 4,
                confettiNumber: 1500,
                confettiColors: [
                    '#444', '#ffd700'
                ],
            })
            setTimeout(() => {
                this.selectedPrize = prize.value
            }, 2000)
        },
        runGame() {
            if (!this.disabled) {
                this.disabled = true
                this.spinning = true
                this.rotateFunc()
            }
        },
        checkAvisAdded() {
            this.loadingCheck = true
            setTimeout(() => {
                this.loadingCheck = false
                this.displayWheel = true
            }, 1500)
        },
        onOpenGoogle() {
            // setInterval(() => {
            //     this.timer--
            // }, 1000)
            this.displayValidation = true
        }
    },

    mounted () {
        let el = document.querySelector('.fw-btn__btn')
        el.click()
    },
}
</script>

<style>
.wheel {
    transform: rotate(90deg);
}

.fw-btn__btn {
    background: #000 !important;

}

.fw-btn__btn:before {
    border-bottom: 18px solid #000 !important;
}

.loader {
    width: 20px;
    height: 20px;
    border: 3px solid #000;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>